<template>
  <div>
    <div class="tech-req__card tech-req__card_plus">
      <div>
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ text.sz_surface_add[language] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plus from "@/assets/images/tech/plus.svg";
import text from "@/assets/text/tech.json";

export default {
  name: "AccuracyType",
  data() {
    return {
      text,
      activeClick: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    surfaceClicked(name) {
      if (this.activeClick) {
        console.log(name);
      }
    },
  },
  mounted() {
    this.$store.commit("modelV2/setSurfaceClick", this.surfaceClicked);
  },
  components: {
    Plus,
  },
};
</script>
